import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import JrInfiniteScrollPlace from './JrInfiniteScrollPlace';
import JrBucketR2 from './JrBucketR2';
import logo from './logo.svg';
import './App.css';
import { Icon } from '@iconify/react';

function App() {
  const [spinDuration, setSpinDuration] = useState(30);
  const [modalOpen, setModalOpen] = useState(false);
  let timeoutId;

  const handleMouseDown = () => {
    setSpinDuration(1);
    timeoutId = setTimeout(() => {
      setSpinDuration(30);
    }, 8000);
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutId);
    setSpinDuration(30);
  };

  const handleTouchStart = () => {
    handleMouseDown();
  };

  const handleTouchEnd = () => {
    handleMouseUp();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            style={{ animationDuration: `${spinDuration}s` }}
          />
          <h1 className="text-2xl font-bold">Photos&nbsp;&infin;&nbsp;Forever</h1>
          <Icon icon="material-symbols:swipe-up-outline-sharp" width="1.2em" height="1.2em" />
        </header>
        <main className="container mx-auto">
          <JrInfiniteScrollPlace />
        </main>
        <footer className="text-center py-4">
          <button className="btn" onClick={openModal}>cloud</button>
          <p className="text-gray-600">Jesse&nbsp;&infin;&nbsp;Roper</p>
        </footer>

        {/* Modal for Upload */}
        {modalOpen && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <p><pre>cloudflare worker</pre></p>
              <button className="close-btn" onClick={closeModal}>Close</button>
              <JrBucketR2 />
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;

