import React, { useState, useEffect } from 'react';

const JrInfiniteScrollPlace = () => {
  const [photoList, setPhotoList] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedPhoto, setSelectedPhoto] = useState(null); // To track the clicked photo for full-screen view
  const photosPerPage = 10;

  useEffect(() => {
    loadPhotos();
  }, []);

  const loadPhotos = () => {
    const photos = Array.from({ length: photosPerPage }, () => (
      `https://picsum.photos/600/400?grayscale&random=${Math.floor(Math.random() * 1000)}`
    ));
    setPhotoList((prevPhotos) => [...prevPhotos, ...photos]);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1
      ) {
        setPage((prevPage) => {
          loadPhotos();
          return prevPage + 1;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const closeFullScreen = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="infinite-scroll-container">
      <div className="photos-list">
        {photoList.map((photo, index) => (
          <div key={index} className="photo-item">
            <img
              src={photo}
              alt={`Random Photo ${index + 1}`}
              className="w-full h-auto rounded cursor-pointer"
              onClick={() => handlePhotoClick(photo)}
            />
          </div>
        ))}
      </div>

      {selectedPhoto && (
        <div className="full-screen-overlay" onClick={closeFullScreen}>
          <img
            src={selectedPhoto}
            alt="Full screen"
            className="full-screen-photo"
          />
        </div>
      )}
    </div>
  );
};

export default JrInfiniteScrollPlace;

