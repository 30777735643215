import React, { useState } from 'react';

const JrBucketR2 = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedUrls = await Promise.all(files.map(uploadImage));
    setImageUrls(uploadedUrls);
  };

  const uploadImage = async (file) => {
    const response = await fetch(`https://server.jessejesse.workers.dev/${file.name}`, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    if (response.ok) {
      return `https://server.jessejesse.workers.dev/${file.name}`;
    } else {
      throw new Error('Upload failed');
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    alert('Copied to clipboard!');
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="bg-blue-500 text-white py-2 px-4 rounded"
      >
        Upload Images
      </button>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Upload Images</h2>
            <input
              type="file"
              multiple
              onChange={handleFileUpload}
              className="mb-4"
            />
            <div className="flex flex-col">
              {imageUrls.map((url, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    value={url}
                    readOnly
                    className="border border-gray-300 rounded p-2 flex-grow mr-2"
                  />
                  <button
                    onClick={() => copyToClipboard(url)}
                    className="bg-green-500 text-white py-2 px-4 rounded"
                  >
                    Copy
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default JrBucketR2;

